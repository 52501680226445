import React from "react"

import Button from "../../UI/Button"
import Input from "../../UI/Input"

import styles from "./ContactUsForm.module.css"

const ContactUsFormView = () => {
  return (
    <div className={styles.wrapper}>
      <div className={styles.titleWrapp}>
        <p>
          If you have any questions or comments about Handicaploan.com, we
          invite you to reach out to us through the following form.
        </p>
      </div>
      <div className={styles.inputWrapp}>
        <Input placeholder="Your Name" />
        <Input placeholder="Your Email" />
        <textarea
          className={styles.input}
          placeholder="Your Question or Comment"
        />
      </div>
      <Button>GET STARTED</Button>
    </div>
  )
}

export default ContactUsFormView
