import React from "react"

import Layout from "../components/Layout"
import ContactUs from "../components/ContactUs"
import SEO from "../components/seo"

const ContactUsPage = ({ location, history }) => (
  <Layout location={location} history={history}>
    <SEO title="ContactUs" />
    <ContactUs />
  </Layout>
)

export default ContactUsPage
