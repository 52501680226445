import React from "react"
import ImageSection from "../ImageSection"

import ContactUsForm from "./ContactUsForm"
import styles from "./ContactUs.module.css"

const ContactUs = () => {
  return (
    <div>
      <ImageSection />
      <section className={styles.section}>
        <h1>CONTACT US</h1>
        <div className={styles.formCont}>
          <ContactUsForm />
        </div>
      </section>
    </div>
  )
}

export default ContactUs
